import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from '../app/external/login/login.component';
import { AuthGuard }from './services/auth.guard';
import {PageNotFoundComponent} from './common/page-not-found/page-not-found.component';
import { ResetpasswordComponent } from './external/resetpassword/resetpassword.component';
import { LinkexpireComponent } from './external/linkexpire/linkexpire.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login'},
  { path: 'login', component: LoginComponent },
  { path: 'reset-password/:token', component: ResetpasswordComponent },
  { path: 'link-expired', component: LinkexpireComponent },
  { path:'',canActivate: [AuthGuard], loadChildren: () => import('./internal/internal.module').then(m => m.InternalModule)},
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }