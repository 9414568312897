import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_END_POINT_Dev, API_END_POINT_Live } from './global';
import swal from "sweetalert2";
import { Router } from '@angular/router';
import { generalModel, icons, menu, BtnText, permission, tabArray, optionArray } from '../shared/models/general.model';
import { Subject } from 'rxjs';
import { apiUrl } from '../global/global';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  apiEndpoint: string = '';
  showLoader: boolean = true;
  icon = icons;
  menu = menu;
  btnText = BtnText;
  generalModel = new generalModel();
  Permission = permission;
  server: string = 'dev';
  public _albums = [];
  constructor(private http: HttpClient, private router: Router) {
    if (localStorage.getItem('server')) {
      this.server = localStorage.getItem('server');
    }
    if (this.server == 'dev') {
      this.apiEndpoint = API_END_POINT_Dev;
    } else if (this.server == 'live') {
      this.apiEndpoint = API_END_POINT_Live;
    }
  }



  getData(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }

  getDetail(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, {});
  }



  putData(url, param) {
    return this.http.put<any>(this.apiEndpoint + url, { params: param });
  }

  postData(url, value) {
    return this.http.post<any>(this.apiEndpoint + url, value);
  }

  upateData(url, value) {
    return this.http.put<any>(this.apiEndpoint + url, value);
  }


  postUpdate(url, value) {
    return this.http.put<any>(this.apiEndpoint + url, value);
  }

  postNewData(url, key, value) {
    return this.http.post<any>(this.apiEndpoint + url, key, value);
  }

  showAlert(title: string, text: string) {
    swal.fire({
      title: title,
      text: text,
      showConfirmButton: false,
      timer: 1500
    })

  }

  errorAlert(text, status) {
    swal.fire({
      title: 'Oops...',
      text: text,
      
    
    }).then((result) => {
      if (status) {
        localStorage.removeItem('adminToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('adminToken');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('type-redirect');
        localStorage.removeItem('image');
        localStorage.removeItem('permissionData');
        this.router.navigate(['/login']);

      }
    })
  }

  toast(title, text) {
    const toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 4000,
      background: '#2FC863',

    });
    toast.fire({
      title: title,
      text: text
    })


  }



  // Show and hide Loader
  private loaderSubject = new Subject<Boolean>();
  loaderState = this.loaderSubject.asObservable();

  loader(value) { this.loaderSubject.next(value); }

  setdata(data) {
    for (let x of tabArray) {
      let array1 = {}
      for (let y of x.value) {
        array1[optionArray[y].name] = (data[x.name] == undefined || data[x.name][optionArray[y].name] == undefined ? false : data[x.name][optionArray[y].name]);
      }
      permission[x.name] = array1;
    }
    permission['status'] = "updated";
    return true;
  }

}