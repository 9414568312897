import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './external/login/login.component'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FormsModule,  ReactiveFormsModule } from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { ResetpasswordComponent } from './external/resetpassword/resetpassword.component'; 
import { LoaderComponent } from './common/loader/loader.component';

import { DataSharingService } from './services/data.share.service';
import {AsyncPipe, CommonModule} from '@angular/common';
import { InterceptorService } from './services/interceptor.service';
import {ToastrModule} from 'ngx-toastr';

import { NgxSpinnerModule } from 'ngx-spinner';
import { LinkexpireComponent } from './external/linkexpire/linkexpire.component';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    ResetpasswordComponent,
    LoaderComponent,
    LinkexpireComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    NgxSpinnerModule,
   
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
 

  ],
  providers: [ AsyncPipe, {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  },
    NgxSpinnerModule,
    DataSharingService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
