import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import swal from "sweetalert2";
import { AdminService } from '../../services/admin.service';
import * as CONSTANT from '../../services/constants';
import { loginModel, resetPasswordModel } from '../../shared/models/login-model';
import { HttpParams } from '@angular/common/http';
import { API_END_POINT_Dev, API_END_POINT_Live } from '../../services/global';
import { apiUrl } from '../../global/global';
import * as CryptoJS from 'crypto-js';



@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    public form: FormGroup;
    public resetPasswordForm: FormGroup;
    showError = false;
    show = true;
    public loading = false;
    image:any;
    loginModel = new loginModel();
    resetPasswordModel = new resetPasswordModel();
    rememberme = false;
    tokenFromUI = '0123456789123456';
    isRemember = false;
    constructor(private formBuilder: FormBuilder,
        private router: Router,
       
        private api: AdminService
    ) {
        this.form = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.pattern(CONSTANT.EMAIL_REGEX)])],
            password: ['', Validators.required]
        });

        this.resetPasswordForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.pattern(CONSTANT.EMAIL_REGEX)])],

        })

    }
    ngOnInit() {
        // console.log(localStorage.getItem('firebaseToken'));
        let data = localStorage.getItem('adminToken');
        this.show = true;
        if (data)
            this.router.navigate(['/dashboard']);
        let rememberme = localStorage.getItem('rememberme');
        // console.log(rememberme, "rememberme");
        if (rememberme == '1') {
            this.loginModel.email = localStorage.getItem('email');
            this.decryptUsingAES256(localStorage.getItem('password'));
            this.isRemember = true;
            this.rememberme = true;
        }

    }
    loginFun() {
        // console.log("thr")
        if (this.form.valid) {
            if (this.api.server == 'dev') {
                this.api.apiEndpoint = API_END_POINT_Dev;
            } else if (this.api.server == 'live') {
                this.api.apiEndpoint = API_END_POINT_Live;
            }
            this.loading = true;
            const data = {
                'email': this.loginModel.email,
                'password': this.loginModel.password,
                //  'deviceToken': this.msg.getFcmToken()
            };
            this.api.postData('admin/login', data)
                .subscribe(async (res) => {
                    // console.log(res, "login============================");
                    this.loading = false;
                    localStorage.setItem('adminToken', res.data.accessToken);
                    localStorage.setItem('name', res.data.name); 
                    localStorage.setItem('image', res.data.image);
                    localStorage.setItem('loginEmail', res.data.email);  
                    localStorage.setItem('s3Url', res.data.s3Url);
                    localStorage.setItem('s3Folders', JSON.stringify(res.data.s3Folders));
                    localStorage.setItem('permission', res.data.admin_permissions.module);
                    if (this.rememberme) {
                        let encrptedPassword = await this.encryptUsingAES256(this.loginModel.password);
                        localStorage.setItem('rememberme', '1');
                        localStorage.setItem('email', this.loginModel.email);
                        localStorage.setItem('password', encrptedPassword.toString());
                    } else {
                        localStorage.setItem('rememberme', '0');
                        localStorage.setItem('email', '');
                        localStorage.setItem('password', '');
                    }
                   let abc = JSON.parse(res.data.admin_permissions.module);

                    // console.log( abc, "abc=============");                
                    let param = new HttpParams();                  
                    localStorage.setItem('permissionData', res.data.permission);                  
                    if(abc.dashboard===1){
                        this.router.navigate(['/dashboard']);
                        this.api.toast('Edee circle Admin', 'Login Successfull!');
                        return false;
                    }
                    if(abc.users===1){
                        this.router.navigate(['/user']);
                        this.api.toast('Edee circle Admin', 'Login Successfull!');
                        return false;
                    }
                    if(abc.payments===1){
                        this.router.navigate(['/payments']);
                        this.api.toast('Edee circle Admin', 'Login Successfull!');
                        return false;
                    }
                    if(abc.advertisements===1){
                        this.router.navigate(['/advertisements']);
                        this.api.toast('Edee circle Admin', 'Login Successfull!');
                        return false;
                    }
                    if(abc.pricing===1){
                        this.router.navigate(['/pricing']);
                        this.api.toast('Edee circle Admin', 'Login Successfull!');
                        return false;
                    }
                    if(abc.recycleCentres===1){
                        this.router.navigate(['/recyclecenters']);
                        this.api.toast('Edee circle Admin', 'Login Successfull!');
                        return false;
                    }
                    if(abc.recycleRequests===1){
                        this.router.navigate(['/recyclerequests']);
                        this.api.toast('Edee circle Admin', 'Login Successfull!');
                        return false;
                    }
                    if(abc.repairShops===1){
                        this.router.navigate(['/repairshops']);
                        this.api.toast('Edee circle Admin', 'Login Successfull!');
                        return false;
                    }
                    if(abc.disputes===1){
                        this.router.navigate(['/disputes']);
                        this.api.toast('Edee circle Admin', 'Login Successfull!');
                        return false;
                    }
                    if(abc.scans===1){
                        this.router.navigate(['/scans']);
                        this.api.toast('Edee circle Admin', 'Login Successfull!');
                        return false;
                    }                   
                    if(abc.courierPackages===1){
                        this.router.navigate(['/courier']);
                        this.api.toast('Edee circle Admin', 'Login Successfull!');
                        return false;
                    }
                    if(abc.mobileDevices===1){
                        this.router.navigate(['/mobileslist']);
                        this.api.toast('Edee circle Admin', 'Login Successfull!');
                        return false;
                    }
                    if(abc.notifications===1){
                        this.router.navigate(['/notification']);
                        this.api.toast('Edee circle Admin', 'Login Successfull!');
                        return false;
                    }                    
                   
                    
                },
                err => {
                    console.log(err, "errr");
                    this.loading = false;
                });
        } else {
            this.showError = true;
        }
    }

    forgotPassword() {
        this.show = false;
    }

    encryptUsingAES256(value) {
        return new Promise((resolve, reject) => {
            let ciphertext = CryptoJS.AES.encrypt(value, this.tokenFromUI);
            resolve(ciphertext);
        });

    }
    decryptUsingAES256(value) {
        let bytes = CryptoJS.AES.decrypt(value.toString(), this.tokenFromUI);
        let plaintext = bytes.toString(CryptoJS.enc.Utf8);
        this.loginModel.password = plaintext;
    }

    backToLogin() {
        this.show = true;
    }

    resetPassword() {
        if (this.resetPasswordForm.valid) {
            if (this.api.server == 'dev') {
                this.api.apiEndpoint = API_END_POINT_Dev;
            } else if (this.api.server == 'live') {
                this.api.apiEndpoint = API_END_POINT_Live;
            }
            this.loading = true;
            const data = {
                'email': this.resetPasswordModel.email
                //  'deviceToken': this.msg.getFcmToken()
            };
            this.api.postData('admin/forgot_password', data)
                .subscribe(res => {
                    this.loading = false;
                    //this.api.toast('Edee circle Admin', 'Your password successfully sent on your email');
                    swal.fire({
                        icon: 'success',
                        title: '<h2>Edee circle Admin</h2>',
                        html: '<p>Your password successfully sent on your email</p>',
                        showCloseButton: false,                      
                        width: 500
                      })
                      this.resetPasswordModel.email="";
                },
                    err => {
                        console.log(err);
                        this.loading = false;
                    });
        } else {
            this.showError = true;
        }
    }

    setServer(event) {
        localStorage.setItem('server', event.target.value)
    }

    changeRememberme(event) {
        // console.log("checked", event.target.checked);
        this.rememberme = event.target.checked;
    }
}
